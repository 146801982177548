/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-02-21",
    versionChannel: "nightly",
    buildDate: "2025-02-21T00:06:08.515Z",
    commitHash: "ff75da2a050ce45302fade028035002f11dda297",
};
